import { Card, Col, Container, Row } from "react-bootstrap";

function CajareportsCocina(props) {
  const totalcover = () => {
    let coverL,
      coverD = 0;
    coverL = parseFloat(props.datos.paxL);
    coverD = parseFloat(props.datos.paxD);

    let total = coverL + coverD;

    return total;
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title className="mb-3">Daily Log - {props.fecha}</Card.Title>
        {/* <Card.Title>Daily Log - </Card.Title> */}
        <Card.Text>
          <Container style={{ padding: 0, fontSize: "0.8rem" }}>
            <Row>
              <Col xs={5} className="campodatos padre padre">
                <div> Cocina :</div>
              </Col>
              <Col xs={7} className="camporesultados">
                <div> {props.datos.restaurant}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={12} className="separator" style={{ margin: 0 }}>
                Lunch
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Jefe de Cocina </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.jefeCocinaL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Pax:</div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.paxL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Incidencias Maquinaria: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.incMaquinariaL} </div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div>Incidencias con Proveedores: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.incProveedoresL} </div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Incidencias con Trabajadores: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div>{props.datos.incTrabajadoresL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Incidencias en el servicio: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.incServicioL}</div>
              </Col>{" "}
            </Row>

            <Row>
              <Col xs={12} className="campodatoscomment padre">
                <div> Comments: </div>
              </Col>{" "}
              <Col xs={12} className="campocomments">
                <div> {props.datos.commentsL}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="separator" style={{ margin: 0 }}>
                Dinner
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Jefe de Cocina </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.jefeCocinaD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Pax:</div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.paxD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Incidencias Maquinaria: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.incMaquinariaD} </div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div>Incidencias con Proveedores: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.incProveedoresD} </div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Incidencias con Trabajadores: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div>{props.datos.incTrabajadoresD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Incidencias en el servicio: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.incServicioD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={12} className="campodatoscomment padre">
                <div> Comments: </div>
              </Col>{" "}
              <Col xs={12} className="campocomments">
                <div> {props.datos.commentsD}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="separator" style={{ margin: 0 }}>
                ende
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Pax: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                {" "}
                <div> {totalcover()}</div>
              </Col>{" "}
            </Row>
          </Container>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default CajareportsCocina;
