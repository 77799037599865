import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./i18n";
import { RouterProvider } from "react-router-dom";
import "./firebase.js";
import { router } from "./App";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    {/* <ThemeProvider theme={theme}> */}
    {/* <Navbar_APP></Navbar_APP> */}
    <RouterProvider router={router} />
    {/* </ThemeProvider> */}
  </StrictMode>
);
