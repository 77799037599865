import React from "react";
import { Container, Navbar, Nav, Button } from "react-bootstrap";

import "./Navbar.css";
import { useAuth } from "../../hooks/useAuth";

export const AppBar = () => {
  const { user, logout } = useAuth();
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      sticky="top"
      style={{ backgroundColor: "#000" }}
    >
      <Container>
        <Navbar.Brand>Reports GDG</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {user ? (
            <Button
              key={"logout"}
              onClick={logout}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              {"logout"}
            </Button>
          ) : (
            <Nav>
              // <Nav.Link href="/login">Login</Nav.Link>
              //{" "}
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
