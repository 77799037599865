import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Button,
  Row,
  Modal,
} from "react-bootstrap";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Cajareports from "../Component/cajaReport";
import CajareportsCocina from "../Component/cajaReportCocina";
import { TablaAnalisis } from "../Component/tablaAnalisis";

function Cargar_datos(props) {
  let productsList, array;
  if (props.products != null) {
    array = Object.values(props.products);

    if (array.length !== 0) {
      productsList = array.map((product) => {
        let id = product.restaurant + "";
        // console.log("id" + id.indexOf("Cocina"));
        if (id.indexOf("Cocina") === -1) {
          // console.log("hola");
          return (
            <Cajareports datos={product} fecha={props.fecha}></Cajareports>
          );
        } else {
          // console.log("adios");
          return (
            <CajareportsCocina
              datos={product}
              fecha={props.fecha}
            ></CajareportsCocina>
          );
        }
      });
    } else {
      productsList = <div> No Existe Report para el dia seleccionado</div>;
    }
  } else {
    productsList = <div></div>;
  }
  return productsList;
}

function Admin() {
  const fechaDeAyer = () => {
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let ayer = new Date(hoy.getTime() - DIA_EN_MILISEGUNDOS);
    return ayer;
  };

  const [value, onChange] = React.useState(fechaDeAyer());
  const [datosquery, setDatosquery] = React.useState([]);
  const [rest, setRest] = React.useState("LobitoMarbella");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    queryReport(rest, value.toISOString());
  }, [value, rest]);

  async function queryReport(restaurante, fecha) {
    var cfecha = new Date(fecha);
    const q = query(
      collection(db, restaurante),
      where("creado", "==", cfecha.toLocaleDateString("es-ES"))
    );
    const cities = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      var encontrado = false;
      cities.forEach((element) => {
        if (element.id == doc.data().id) {
          encontrado = true;
        }
      });
      if (!encontrado) {
        cities.push(doc.data());
      }
    });
    console.log(cities);
    setDatosquery(cities);
  }

  return (
    <Container className="my-4" style={{ minHeight: "100vh" }}>
      <Row>
        <Col xs={12}>
          <Card className="p-3" style={{ minHeight: "100vh" }}>
            <Card.Title>Admin - Daily Report</Card.Title>
            <hr></hr>
            <Card.Body style={{ padding: 0 }}>
              <Row>
                <Col xs={12} lg={6} sm={12} className="mt-2">
                  <FloatingLabel controlId="floatingSelect" label="Restaurante">
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(val) => setRest(val.target.value)}
                    >
                      <option disabled selected value>
                        Selecciona Restaurante
                      </option>

                      <optgroup label="Lobito">
                        <option value="LobitoMarbella">Lobito Marbella</option>

                        <option value="LobitoMadrid">Lobito Madrid</option>
                        <option value="LobitoLaFinca">Lobito La Finca</option>
                        <option value="CocinaLobitoMarbella">
                          Cocina Lobito Marbella
                        </option>
                        <option value="CocinaLobitoMadrid">
                          Cocina Lobito Madrid
                        </option>
                        <option value="CocinaLobitoLaFinca">
                          Lobito La Finca
                        </option>
                      </optgroup>
                      <optgroup label="BIBO">
                        <option value="BIBOMarbella">BIBO Marbella</option>
                        <option value="BIBODOHA">BIBO DOHA</option>
                        <option value="BIBOMadrid">BIBO Madrid</option>
                        <option value="BIBOTarifa">BIBO Tarifa</option>
                        <option value="BIBOIbiza">BIBO Ibiza</option>
                        <option value="BIBOLondon">BIBO London</option>
                        <option value="CocinaBIBOMarbella">
                          Cocina BIBO Marbella
                        </option>
                        <option value="CocinaBIBODOHA">Cocina BIBO DOHA</option>
                        <option value="CocinaBIBOMadrid">
                          {" "}
                          Cocina BIBO Madrid
                        </option>
                        <option value="CocinaBIBOTarifa">
                          Cocina BIBO Tarifa
                        </option>
                        <option value="CocinaBIBOIbiza">
                          Cocina BIBO Ibiza
                        </option>
                        <option value="CocinaBIBOLondon">
                          Cocina BIBO London
                        </option>
                      </optgroup>
                      <optgroup label="Leña">
                        <option value="LenaMarbella">Leña Marbella</option>
                        <option value="LenaMadrid">Leña Madrid</option>
                        <option value="CocinaLenaMarbella">
                          Cocina Leña Marbella
                        </option>
                        <option value="CocinaLenaMadrid">
                          Cocina Leña Madrid
                        </option>
                        <option value="LenaDubai">Leña Dubai</option>
                        <option value="CocinaLenaDubai">
                          Cocina Leña Dubai
                        </option>
                      </optgroup>
                      <optgroup label="Smoked Room">
                        <option value="SmokedRoom">Smoked Room</option>
                        <option value="CocinaSmokedRoom">
                          Cocina Smoked Room
                        </option>
                        <option value="SmokedRoomDubai">
                          Smoked Room Dubai
                        </option>
                        <option value="CocinaSmokedRoomDubai">
                          Cocina Smoked Room Dubai
                        </option>
                      </optgroup>
                      <optgroup label="Kemuri">
                        <option value="KemuriMarbella">Kemuri Marbella</option>
                        <option value="CocinaKemuriMarbella">
                          Cocina Kemuri Marbella
                        </option>{" "}
                      </optgroup>
                      <optgroup label="Alelí">
                        <option value="AleliMarbella">Alelí Marbella</option>
                        <option value="CocinaAleliMarbella">
                          Cocina Alelí Marbella
                        </option>
                        <option value="AleliBudapest">Alelí Budapest</option>
                      </optgroup>
                      <optgroup label="Tragabuches">
                        <option value="TragabuchesMarbella">
                          Tragabuches Marbella
                        </option>
                        <option value="CocinaTragabuchesMarbella">
                          Cocina Tragabuches Marbella
                        </option>
                        <option value="TragabuchesMadrid">
                          Tragabuches Madrid
                        </option>
                        <option value="CocinaTragabuchesMadrid">
                          Cocina Tragabuches Madrid
                        </option>
                      </optgroup>
                      <optgroup label="Coleccionista">
                        <option value="ColeccionistaMadrid">
                          El Coleccionista Madrid
                        </option>
                        <option value="CocinaColeccionistaMadrid">
                          Cocina El Coleccionista Madrid
                        </option>
                      </optgroup>
                      <optgroup label="CasaDani">
                        <option value="CasaDaniNY">Casa Dani NY</option>
                        <option value="CocinaCasaDaniNY">
                          Cocina Casa Dani NY
                        </option>
                      </optgroup>
                      <optgroup label="PolloVerde">
                        <option value="PolloVerdeNY">Pollo Verde NY</option>
                        <option value="CocinaPolloVerdeNY">
                          Cocina Pollo Verde NY
                        </option>
                      </optgroup>
                      <optgroup label="Produccion">
                        <option value="Produccion">Producción</option>
                      </optgroup>
                      <optgroup label="Lacabane">
                        <option value="LacabaneMarbella">
                          La Cabane Marbella
                        </option>
                        <option value="CocinaLacabaneMarbella">
                          La Cabane Marbella Cocina
                        </option>
                        <option value="PiscinaLacabaneMarbella">
                          La Cabane Marbella Piscina
                        </option>
                      </optgroup>
                    </Form.Select>
                  </FloatingLabel>{" "}
                  <Button
                    variant="outline-secondary"
                    onClick={handleShow}
                    className="mt-2 w-100"
                  >
                    Fecha: {value.toLocaleDateString("es-ES")}
                  </Button>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Body>
                      {" "}
                      <Calendar
                        onChange={(e) => {
                          onChange(e);
                          handleClose();
                        }}
                        value={value}
                        prev2Label={null}
                        next2Label={null}
                        // showNavigation={true}
                        // defaultValue={fechaDeAyer().getDate()}
                        className=" p-0"
                      />
                    </Modal.Body>
                  </Modal>
                  {rest.indexOf("Cocina") == -1 &&
                  rest.indexOf("Produccion") == -1 ? (
                    <>
                      {" "}
                      <TablaAnalisis rest={rest} value={value}></TablaAnalisis>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>

                <Col xs={12} md={12} lg={6} className="mt-2 p-0">
                  <Cargar_datos
                    products={datosquery}
                    fecha={value.toISOString()}
                  ></Cargar_datos>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Admin;
