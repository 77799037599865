import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Button, Row } from "react-bootstrap";
import { db } from "../firebase";
import { getAuth } from "@firebase/auth";
import * as yup from "yup";
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { Formik } from "formik";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import { date } from "yup/lib/locale";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import "react-toastify/dist/ReactToastify.min.css";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  file: yup.mixed().required(),
  terms: yup.bool().required().oneOf([true], "terms must be accepted"),
});

function SelectRestaurantes(props) {
  const { t, i18n } = useTranslation();
  if (props.admin == true) {
    // console.log("admin");
    return (
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>{t("cocina.restaurante")}</Form.Label>
        <Form.Select
          aria-label="Restaurant"
          value={props.selectRestaurant}
          onChange={props.handleOnChangeselect}
        >
          <option disabled selected value>
            Elige una opción
          </option>
          <optgroup label="Lobito">
            <option value="CocinaLobitoMarbella">Lobito Marbella</option>
            <option value="CocinaLobitoMadrid">Lobito Madrid</option>
            <option value="CocinaLobitoDoha">Lobito Doha</option>
            <option value="CocinaLobitoLaFinca">Lobito La Finca</option>
          </optgroup>
          <optgroup label="BIBO">
            <option value="CocinaBIBOMarbella">BIBO Marbella</option>
            <option value="CocinaBIBODOHA">BIBO DOHA</option>
            <option value="CocinaBIBOMadrid">BIBO Madrid</option>
            <option value="CocinaBIBOTarifa">BIBO Tarifa</option>
            <option value="CocinaBIBOIbiza">BIBO Ibiza</option>
            <option value="CocinaBIBOLondon">BIBO London</option>
          </optgroup>
          <optgroup label="Leña">
            <option value="CocinaLenaMarbella">Leña Marbella</option>
            <option value="CocinaLenaMadrid">Leña Madrid</option>
            <option value="CocinaLenaDubai">Leña Dubai</option>
          </optgroup>
          <optgroup label="Smoked Room">
            <option value="CocinaSmokedRoom">Smoked Room Madrid</option>
            <option value="CocinaSmokedRoomDubai">Smoked Room Dubai</option>
          </optgroup>
          <optgroup label="Babette">
            <option value="CocinaBabetteMarbella">Babette Marbella</option>
          </optgroup>
          <optgroup label="CasaDani">
            <option value="CocinaCasaDaniNY">Casa Dani NY</option>
          </optgroup>
          <optgroup label="PolloVerde">
            <option value="CocinaPolloVerdeNY">Pollo Verde NY</option>
          </optgroup>
          <optgroup label="Alelí">
            <option value="CocinaAleliMarbella">Alelí Marbella</option>
          </optgroup>
          <optgroup label="Kemuri">
            <option value="CocinaKemuriMarbella">Kemuri Marbella</option>
          </optgroup>{" "}
          <optgroup label="Kurai">
            <option value="CocinaKuraiMarbella">Kurai Marbella</option>
          </optgroup>
          <optgroup label="Tragabuches">
            <option value="CocinaTragabuchesMarbella">
              Tragabuches Marbella
            </option>
            <option value="CocinaTragabuchesMadrid">Tragabuches Madrid</option>
          </optgroup>
          <optgroup label="Coleccionista">
            <option value="CocinaColeccionistaMadrid">
              El Coleccionista Madrid
            </option>
          </optgroup>
          <optgroup label="Lacabane">
            <option value="CocinaLacabaneMarbella">La Cabane Marbella</option>
          </optgroup>
        </Form.Select>
      </Form.Group>
    );
  } else if (
    (props.admin == false &&
      props.selectRestaurant == "CocinaKemuriMarbella") ||
    props.selectRestaurant == "CocinaKuraiMarbella"
  ) {
    // console.log("admin");
    return (
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>{t("home.restaurante")}</Form.Label>
        <Form.Select
          aria-label="Restaurant"
          value={props.selectRestaurant}
          onChange={props.handleOnChangeselect}
        >
          <option disabled selected value>
            Elige una opción
          </option>

          <optgroup label="Kemuri">
            <option value="CocinaKemuriMarbella">Kemuri Marbella</option>
          </optgroup>
          <optgroup label="Kurai">
            <option value="CocinaKuraiMarbella">Kurai Marbella</option>
          </optgroup>
        </Form.Select>
      </Form.Group>
    );
  } else {
    // console.log("no admin");
    return (
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>{t("cocina.restaurante")}</Form.Label>
        <Form.Select
          aria-label="Restaurant"
          value={props.selectRestaurant}
          onChange={props.handleOnChangeselect}
          disabled
        >
          <option disabled selected value>
            Elige una opción
          </option>
          <optgroup label="Lobito">
            <option value="CocinaLobitoMarbella">Lobito Marbella</option>
            <option value="CocinaLobitoMadrid">Lobito Madrid</option>
            <option value="CocinaLobitoDoha">Lobito Doha</option>
            <option value="CocinaLobitoLaFinca">Lobito La Finca</option>
          </optgroup>
          <optgroup label="BIBO">
            <option value="CocinaBIBOMarbella">BIBO Marbella</option>
            <option value="CocinaBIBODOHA">BIBO DOHA</option>
            <option value="CocinaBIBOMadrid">BIBO Madrid</option>
            <option value="CocinaBIBOTarifa">BIBO Tarifa</option>
            <option value="CocinaBIBOIbiza">BIBO Ibiza</option>
            <option value="CocinaBIBOLondon">BIBO London</option>
          </optgroup>
          <optgroup label="Leña">
            <option value="CocinaLenaMarbella">Leña Marbella</option>
            <option value="CocinaLenaMadrid">Leña Madrid</option>
            <option value="CocinaLenaDubai">Leña Dubai</option>
          </optgroup>
          <optgroup label="Smoked Room">
            <option value="CocinaSmokedRoom">Smoked Room Madrid</option>
            <option value="CocinaSmokedRoomDubai">Smoked Room Dubai</option>
          </optgroup>
          <optgroup label="Babette">
            <option value="CocinaBabetteMarbella">Babette Marbella</option>
          </optgroup>
          <optgroup label="CasaDani">
            <option value="CocinaCasaDaniNY">Casa Dani NY</option>
          </optgroup>
          <optgroup label="PolloVerde">
            <option value="CocinaPolloVerdeNY">Pollo Verde NY</option>
          </optgroup>
          <optgroup label="Alelí">
            <option value="CocinaAleliMarbella">Alelí Marbella</option>
          </optgroup>
          <optgroup label="Kemuri">
            <option value="CocinaKemuriMarbella">Kemuri Marbella</option>
          </optgroup>{" "}
          <optgroup label="Kurai">
            <option value="CocinaKuraiMarbella">Kurai Marbella</option>
          </optgroup>
          <optgroup label="Tragabuches">
            <option value="CocinaTragabuchesMarbella">
              Tragabuches Marbella
            </option>
            <option value="CocinaTragabuchesMadrid">Tragabuches Madrid</option>
          </optgroup>
          <optgroup label="Coleccionista">
            <option value="CocinaColeccionistaMadrid">
              El Coleccionista Madrid
            </option>
          </optgroup>
          <optgroup label="Lacabane">
            <option value="CocinaLacabaneMarbella">La Cabane Marbella</option>
          </optgroup>
        </Form.Select>
      </Form.Group>
    );
  }
}

function Cocina(props) {
  const [selectRestaurant, setSelectRestaurant] = React.useState();
  const [datosquery, setDatosquery] = React.useState([]);
  const [admin, setAdmin] = React.useState(false);
  const [totalcoverL, setTotalcoverL] = React.useState(0);
  const [totalcoverD, setTotalcoverD] = React.useState(0);
  const [show, setShow] = useState(false);

  const notify = () =>
    toast.success(t("cocina.toastOk"), {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      onClose: () => window.location.replace("/"),
    });
  const notifyerror = (error) =>
    toast.danger("Error" + error, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  const subirDatosIncidencia = async (e) => {
    console.log(e);
    const fechacreado = Date.now();
    const hoy = new Date(fechacreado);

    try {
      const docRef = await addDoc(collection(db, selectRestaurant), {
        restaurant: selectRestaurant,
        jefeCocinaL: e.jefeCocinaL,
        incMaquinariaL: e.incMaquinariaL,
        incProveedoresL: e.incProveedoresL,
        incTrabajadoresL: e.incTrabajadoresL,
        incServicioL: e.incServicioL,
        commentsL: e.commentsL,
        jefeCocinaD: e.jefeCocinaD,
        incMaquinariaD: e.incMaquinariaD,
        incProveedoresD: e.incProveedoresD,
        incTrabajadoresD: e.incTrabajadoresD,
        incServicioD: e.incServicioD,
        commentsD: e.commentsD,
        jefeCocinaB: e.jefeCocinaB,
        incMaquinariaB: e.incMaquinariaB,
        incProveedoresB: e.incProveedoresB,
        incTrabajadoresB: e.incTrabajadoresB,
        incServicioB: e.incServicioB,
        commentsB: e.commentsB,
        fecha: Timestamp.now(),
        jefePartidaL: e.jefePartidaL,
        paxL: e.paxL,
        jefePartidaB: e.jefePartidaB,
        paxB: e.paxB,
        jefePartidaD: e.jefePartidaD,
        paxD: e.paxD,

        secCocinaB: e.secCocinaB,
        supervisorB: e.supervisorB,
        cocineroB: e.cocineroB,
        ayucocinaB: e.ayucocinaB,
        officeB: e.officeB,
        secCocinaL: e.secCocinaL,
        supervisorL: e.supervisorL,
        cocineroL: e.cocineroL,
        ayucocinaL: e.ayucocinaL,
        officeL: e.officeL,
        secCocinaD: e.secCocinaD,
        supervisorD: e.supervisorD,
        cocineroD: e.cocineroD,
        ayucocinaD: e.ayucocinaD,
        officeD: e.officeD,

        incidenciaId: uuidv4(),
        creado: datevalue.toLocaleDateString(),
        hora: datevalue.toTimeString(),
      }).then(notify());
    } catch (e) {
      console.error("Error adding document: ", e);
      notifyerror(notifyerror(e));
    }
  };

  const handleOnChangeselect = (e) => {
    e.preventDefault();

    setSelectRestaurant(e.target.value);
  };
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    rolequery();
  }, [user]);

  async function rolequery() {
    const q = query(
      collection(db, "user"),
      where("uid", "==", user !== null ? user.uid : "")
    );
    const cities = [];

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      var encontrado = false;
      cities.forEach((element) => {
        if (element.uid == doc.data().uid) {
          encontrado = true;
        }
      });
      if (!encontrado) {
        cities.push(doc.data());
      }
    });
    // console.log(cities);

    setDatosquery(cities);

    if (cities.length != 0) {
      if (cities[0].rol == "Admin") {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
      setSelectRestaurant(cities[0].idrestaurante);
    }
  }
  const fechaDeAyer = () => {
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let ayer = new Date(hoy.getTime() - DIA_EN_MILISEGUNDOS);
    return ayer;
  };

  const [datevalue, dateonChange] = useState(fechaDeAyer());

  const { t, i18n } = useTranslation();

  const formatNumber = (value) => {
    let valor = value;

    valor = valor.replace(/\,/g, ".");

    return valor;
  };
  return (
    <Container className="my-5">
      <ToastContainer />
      <Row>
        <Col xs={12}>
          <Card className="p-3">
            <Card.Title>{t("cocina.title")}</Card.Title>
            <hr></hr>
            <Card.Body>
              <Formik
                validationSchema={schema}
                initialValues={{
                  // restaurant: selectRestaurant,
                  paxB: "",
                  incProveedoresB: "",
                  incTrabajadoresB: "",
                  incServicioB: "",
                  commentsB: "",
                  incMaquinariaB: "",

                  jefeCocinaB: "",
                  secCocinaB: "",
                  supervisorB: "",
                  cocineroB: "",
                  jefePartidaB: "",
                  ayucocinaB: "",
                  officeB: "",

                  paxL: "",
                  incProveedoresL: "",
                  incTrabajadoresL: "",
                  incServicioL: "",
                  commentsL: "",
                  incMaquinariaL: "",

                  jefeCocinaL: "",
                  secCocinaL: "",
                  supervisorL: "",
                  jefePartidaL: "",
                  cocineroL: "",
                  ayucocinaL: "",
                  officeL: "",

                  paxD: "",
                  incProveedoresD: "",
                  incTrabajadoresD: "",
                  incServicioD: "",
                  commentsD: "",
                  incMaquinariaD: "",

                  jefeCocinaD: "",
                  secCocinaD: "",
                  supervisorD: "",
                  jefePartidaD: "",
                  cocineroD: "",
                  ayucocinaD: "",
                  officeD: "",
                }}
              >
                {({ handleChange, values, isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col xs={12} md={6}>
                        <SelectRestaurantes
                          admin={admin}
                          text={t("cocina.restaurante")}
                          selectRestaurant={selectRestaurant}
                          handleOnChangeselect={handleOnChangeselect}
                        ></SelectRestaurantes>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.selectDay")}</Form.Label>
                          <DatePicker
                            className="fecha_select"
                            onChange={dateonChange}
                            minDate={fechaDeAyer()}
                            maxDate={new Date()}
                            value={datevalue}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {selectRestaurant == "CocinaTragabuchesMarbella" ||
                    selectRestaurant == "CocinaTragabuchesMadrid" ? (
                      <>
                        {" "}
                        <Row>
                          <Col xs={12} className="separator">
                            {t("cocina.breakfast")}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("cocina.pax")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="paxB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.paxB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("cocina.incMaquinaria")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="incMaquinariaB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.incMaquinariaB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("cocina.incProveedores")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="incProveedoresB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.incProveedoresB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("cocina.incTrabajadores")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="incTrabajadoresB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.incTrabajadoresB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("cocina.incServicio")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="incServicioB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.incServicioB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("cocina.jefedecocina")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="jefeCocinaB"
                                value={values.jefeCocinaB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("cocina.segundoCocina")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="secCocinaB"
                                value={values.secCocinaB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("cocina.supervisor")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="supervisorB"
                                value={values.supervisorB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("cocina.jefepartida")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="jefePartidaB"
                                value={values.jefePartidaB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("cocina.cocinero")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="cocineroB"
                                value={values.cocineroB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("cocina.n")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="ayucocinaB"
                                value={values.ayucocinaB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("cocina.office")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="officeB"
                                value={values.officeB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("cocina.comentarios")}</Form.Label>
                              <Form.Control
                                as="textarea"
                                style={{ height: "100px" }}
                                name="commentsB"
                                value={values.commentsB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col xs={12} className="separator">
                        {t("cocina.lunch")}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.pax")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="paxL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.paxL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incMaquinaria")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="incMaquinariaL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incMaquinariaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incProveedores")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="incProveedoresL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incProveedoresL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incTrabajadores")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="incTrabajadoresL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incTrabajadoresL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incServicio")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="incServicioL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incServicioL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.jefedecocina")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="jefeCocinaL"
                            value={values.jefeCocinaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.segundoCocina")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="secCocinaL"
                            value={values.secCocinaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.supervisor")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="supervisorL"
                            value={values.supervisorL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.jefepartida")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="jefePartidaL"
                            value={values.jefePartidaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.cocinero")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="cocineroL"
                            value={values.cocineroL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.ayudanteCocina")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="ayucocinaL"
                            value={values.ayucocinaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.office")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="officeL"
                            value={values.officeL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.comentarios")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="commentsL"
                            value={values.commentsL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="separator">
                        {t("cocina.dinner")}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.pax")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="paxD"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.paxD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incMaquinaria")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="incMaquinariaD"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incMaquinariaD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incProveedores")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="incProveedoresD"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incProveedoresD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incTrabajadores")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="incTrabajadoresD"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incTrabajadoresD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incServicio")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="incServicioD"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incServicioD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.jefedecocina")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="jefeCocinaD"
                            value={values.jefeCocinaD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.segundoCocina")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="secCocinaD"
                            value={values.secCocinaD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.supervisor")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="supervisorD"
                            value={values.supervisorD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.jefepartida")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="jefePartidaD"
                            value={values.jefePartidaD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.cocinero")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="cocineroD"
                            value={values.cocineroD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.ayudanteCocina")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="ayucocinaD"
                            value={values.ayucocinaD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.office")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="officeD"
                            value={values.officeD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.comentarios")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="commentsD"
                            value={values.commentsD}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col xs={12} className="separator">
                        ende
                      </Col>
                    </Row>
                    <Row>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formGroupEmail"
                      >
                        <Form.Label column sm={4} xs={12}>
                          {t("cocina.tcover")}
                        </Form.Label>
                        <Col sm={8} xs={12}>
                          <Form.Control
                            style={{ textAlign: "right" }}
                            type="text"
                            name="totalcover"
                            value={
                              parseFloat(totalcoverD) + parseFloat(totalcoverL)
                            }
                            disabled
                            // onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                    </Row> */}
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        isSubmitting = true;
                        confirmAlert({
                          title: t("cocina.warningtitle"),
                          message: t("cocina.warningdesc"),
                          buttons: [
                            {
                              label: t("cocina.enviar"),
                              onClick: (e) => subirDatosIncidencia(values),
                            },
                            {
                              label: t("cocina.cancelar"),
                              onClick: () => (isSubmitting = false),
                            },
                          ],
                        });
                      }}
                      className="w-100 my-3"
                      disabled={isSubmitting}
                    >
                      {t("cocina.enviar")}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Cocina;
