import {
  RiShowersLine,
  RiSnowyLine,
  RiSunCloudyLine,
  RiSunLine,
} from "react-icons/ri";

export const geticonTiempo = (tiempoAtmosferico) => {
  switch (
    typeof tiempoAtmosferico === "string" ? tiempoAtmosferico.toLowerCase() : ""
  ) {
    case "soleado":
      return <RiSunLine size={"20px"} />;
    case "nublado":
      return <RiSunCloudyLine size={"20px"} />;
    case "lluvioso":
      return <RiShowersLine size={"20px"} />;
    case "nevado":
      return <RiSnowyLine size={"20px"} />;
    default:
      return null;
  }
};

export const compruebaString = (variable) => {
  var resultado = variable;
  if (typeof resultado === "string") {
    resultado = 0;
  }
  return resultado;
};
