import { collection, query, where, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";
import Admin from "./Admin";
import { db } from "../firebase";
import { Col, Container, Row } from "react-bootstrap";

import Cocina from "./Cocina";
import Produccion from "./Produccion";
import Restaurantes from "./Restaurantes";

function CargarDatos(props) {
  // console.log(props.user);
  if (props.user === "Admin") {
    return <Admin></Admin>;
  } else if (props.user === "Restaurante") {
    return <Restaurantes></Restaurantes>;
  } else if (props.user === "Cocina") {
    return <Cocina></Cocina>;
  } else if (props.user === "Produccion") {
    return <Produccion></Produccion>;
  } else {
    return (
      <Container className="my-4">
        <Row>
          <Col xs={12} style={{ textAlign: "center" }}>
            <div className="lds-grid">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const Report = (props) => {
  const [admin, setAdmin] = React.useState("");

  useEffect(() => {
    // console.log("hola"+props.user);
    var user = localStorage.getItem("uid");
    pruebacallback(user);
  }, []);

  function pruebacallback(user) {
    rolequery(user).then(function (value) {
      // setTimeout(10000);
      const cities = [];
      value.forEach((doc) => {
        var encontrado = false;
        cities.forEach((element) => {
          if (element.uid === doc.data().uid) {
            encontrado = true;
          }
        });
        if (!encontrado) {
          cities.push(doc.data());
        }
      });
      // console.log(cities);

      // setDatosquery(cities);

      if (cities.length !== 0) {
        setAdmin(cities[0].rol);
      }
    });
  }

  async function rolequery(user) {
    // const user = null;

    const q = query(
      collection(db, "user"),
      where("uid", "==", user !== null ? user : "")
    );

    // const q = query(collection(db, restaurante));
    const querySnapshot = await getDocs(q);
    return querySnapshot;
    // setDatosquery(doc.data());
  }

  return (
    <>
      {" "}
      <CargarDatos user={admin}></CargarDatos>
    </>
  );
};

export default Report;
