import React from "react";
import { compruebaString } from "../Utils/Utils";
import { Line } from "react-chartjs-2";
const options4 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Total last 14 days",
    },
  },
};
export const GraficaInforme = (props) => {
  const { data } = props;
  const avet = data.map(
    (doc) =>
      (compruebaString(doc.totalnetrevenueL) +
        compruebaString(doc.totalnetrevenueD) +
        compruebaString(doc.totalnetrevenueB)) /
      (compruebaString(doc.coverL) +
        compruebaString(doc.coverD) +
        compruebaString(doc.coverB))
  );

  const labels = data.map((doc) => {
    const date = new Date(doc.fecha.seconds * 1000);
    return date.toLocaleDateString('es-ES'); // Formatea la fecha como una cadena legible
  });

  const datachart4 = {
    labels,
    datasets: [
      {
        label: "Total Ticket Medio",
        data: avet,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line options={options4} data={datachart4} />;
};
