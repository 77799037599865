import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { compruebaString } from "../Utils/Utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Total Revenue last 14 days",
    },
  },
};
const options2 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Covers last 14 days",
    },
  },
};
const options3 = {
  updateMode: "scale",
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Average Ticket last 14 days",
    },
  },
};
const options4 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Total last 14 days",
    },
  },
};

export const RevenueChart = ({ data }) => {
  console.log(data);
  const labels = data.map((doc) => {
    const date = new Date(doc.fecha.seconds * 1000);
    return date.toLocaleDateString('es-ES'); // Formatea la fecha como una cadena legible
  });

  const totalL = data.map((doc) => compruebaString(doc.totalnetrevenueL));
  const totalD = data.map((doc) => compruebaString(doc.totalnetrevenueD));
  const totalB = data.map((doc) => compruebaString(doc.totalnetrevenueB));
  const datachart = {
    labels,
    datasets: [
      {
        label: "Total Revenue B",
        data: totalB,
        borderColor: "rgb(150, 12, 215)",
        backgroundColor: "rgba(150, 12, 215, 0.5)",
      },
      {
        label: "Total Revenue L",
        data: totalL,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Total Revenue D",
        data: totalD,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const coverL = data.map((doc) => compruebaString(doc.coverL));
  const coverD = data.map((doc) => compruebaString(doc.coverD));
  const coverB = data.map((doc) => compruebaString(doc.coverB));
  const datachart2 = {
    labels,
    datasets: [
      {
        label: "Cover B",
        data: coverB,
        borderColor: "rgb(150, 12, 215)",
        backgroundColor: "rgba(150, 12, 215, 0.5)",
      },
      {
        label: "Cover L",
        data: coverL,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Cover D",
        data: coverD,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const ticketL = data.map(
    (doc) => compruebaString(doc.totalnetrevenueL) / compruebaString(doc.coverL)
  );
  const ticketD = data.map(
    (doc) => compruebaString(doc.totalnetrevenueD) / compruebaString(doc.coverD)
  );
  const ticketB = data.map(
    (doc) => compruebaString(doc.totalnetrevenueB) / compruebaString(doc.coverB)
  );
  const datachart3 = {
    labels,
    datasets: [
      {
        label: "Cover B",
        data: ticketB,
        borderColor: "rgb(150, 12, 215)",
        backgroundColor: "rgba(150, 12, 215, 0.5)",
      },
      {
        label: "Cover L",
        data: ticketL,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Cover D",
        data: ticketD,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const tickett = data.map(
    (doc) =>
      compruebaString(doc.totalnetrevenueL) +
      compruebaString(doc.totalnetrevenueD) +
      compruebaString(doc.totalnetrevenueB)
  );
  const covert = data.map(
    (doc) =>
      compruebaString(doc.coverL) +
      compruebaString(doc.coverD) +
      compruebaString(doc.coverB)
  );
  const avet = data.map(
    (doc) =>
      (compruebaString(doc.totalnetrevenueL) +
        compruebaString(doc.totalnetrevenueD) +
        compruebaString(doc.totalnetrevenueB)) /
      (compruebaString(doc.coverL) +
        compruebaString(doc.coverD) +
        compruebaString(doc.coverB))
  );

  const datachart4 = {
    labels,
    datasets: [
      {
        label: "Total Revenue",
        data: tickett,
        borderColor: "rgb(150, 12, 215)",
        backgroundColor: "rgba(150, 12, 215, 0.5)",
      },

      {
        label: "Total Cover",
        data: covert,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Total Ticket Medio",
        data: avet,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <>
      <Line options={options} data={datachart} />
      <Line options={options2} data={datachart2} />
      <Line options={options3} data={datachart3} />
      <Line options={options4} data={datachart4} />
    </>
  );
};
