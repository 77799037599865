import { Card, Col, Container, Row } from "react-bootstrap";
import { compruebaString, geticonTiempo } from "../Utils/Utils";
import { isMobile } from "react-device-detect";

function Cajareports(props) {
  const totalcover = () => {
    let coverL,
      coverB,
      coverD = 0;
    coverL = parseFloat(compruebaString(props.datos.coverL));
    coverD = parseFloat(compruebaString(props.datos.coverD));
    coverB = parseFloat(compruebaString(props.datos.coverB));
    let total = coverL + coverD + coverB;

    return total;
  };

  const totalrevenue = () => {
    let totalrevenueL,
      totalrevenueB,
      totalrevenueD = 0;

    totalrevenueL = parseFloat(props.datos.totalnetrevenueL);
    totalrevenueD = parseFloat(props.datos.totalnetrevenueD);
    totalrevenueB = parseFloat(props.datos.totalnetrevenueB);
    let total = totalrevenueL + totalrevenueD + totalrevenueB;

    return total.toFixed(2);
  };

  const totalPropinas = () => {
    let totalPropinasL,
      totalPropinasB,
      totalPropinasD = 0;

    totalPropinasL = parseFloat(compruebaString(props.datos.propinasL));
    totalPropinasD = parseFloat(compruebaString(props.datos.propinasD));
    totalPropinasB = parseFloat(compruebaString(props.datos.propinasB));
    let total = totalPropinasL + totalPropinasD + totalPropinasB;

    return total.toFixed(2);
  };
  const ticketmedio = (cover, renevue) => {
    let tcover,
      trevenue = 0;

    tcover = parseFloat(cover);
    trevenue = parseFloat(renevue);

    let ticket = trevenue / tcover;
    return ticket.toFixed(2);
  };
  const format = (nStr) => {
    nStr += "";
    // nStr = nStr.replace(/,/g,'');
    // console.log(nStr);
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "," + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title className="mb-3">
          Daily Log - {new Date(props.fecha).toLocaleDateString("es-ES")}
        </Card.Title>
        {/* <Card.Title>Daily Log - </Card.Title> */}
        <Card.Text>
          <Container style={{ padding: 0, fontSize: "0.8rem" }}>
            <Row>
              <Col xs={5} className="campodatos padre padre">
                <div> Restaurant:</div>
              </Col>
              <Col xs={7} className="camporesultados">
                <div> {props.datos.restaurant}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre padre">
                <div> Weather: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div>
                  {" "}
                  {props.datos.weather} {geticonTiempo(props.datos.weather)}
                </div>
              </Col>
            </Row>
            {props.datos.restaurant == "TragabuchesMarbella" ||
            props.datos.restaurant == "TragabuchesMadrid" ? (
              <>
                {" "}
                <Row>
                  <Col xs={12} className="separator" style={{ margin: 0 }}>
                    Breakfast
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Manager/MD: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados">
                    <div> {props.datos.managerB}</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Covers:</div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados stried">
                    <div> {props.datos.coverB}</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Total Food Revenue: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados">
                    <div> {format(props.datos.totalnetrevenueFoodB)} €</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Total Drinks Revenue: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados stried">
                    <div> {format(props.datos.totalnetrevenueDrinksB)} €</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Total Net Revenue: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados">
                    <div> {format(props.datos.totalnetrevenueB)} €</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Propinas: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados stried">
                    <div> {format(props.datos.propinasB)} €</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Ticket Medio: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados">
                    <div>
                      {" "}
                      {format(
                        ticketmedio(
                          props.datos.coverB,
                          props.datos.totalnetrevenueB
                        )
                      )}{" "}
                      €
                    </div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Habituales: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados stried">
                    <div> {props.datos.habitualesB}</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> VIP's: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados">
                    <div> {props.datos.vipB}</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Invitación: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados stried">
                    <div> {props.datos.regB}</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> Walk-ins: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados">
                    <div> {props.datos.walkinsB}</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={5} className="campodatos padre">
                    <div> No show: </div>
                  </Col>{" "}
                  <Col xs={7} className="camporesultados stried">
                    <div> {props.datos.noshowB}</div>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col xs={12} className="campodatoscomment padre">
                    <div> Incidencias:</div>
                  </Col>{" "}
                  <Col xs={12} className="campocomments">
                    <div>{props.datos.incidentesB}</div>
                  </Col>
                </Row>
                {!isMobile ? (
                  <>
                    {" "}
                    <Row>
                      <Col xs={2} className="campodatos padre">
                        Barmans
                      </Col>
                      <Col xs={2} className="campodatos padre">
                        Camareros
                      </Col>
                      <Col xs={2} className="campodatos padre">
                        Ayudantes de Camareros
                      </Col>
                      <Col xs={2} className="campodatos padre">
                        Somelier
                      </Col>
                      <Col xs={2} className="campodatos padre">
                        Abrillantador
                      </Col>
                      <Col xs={2} className="campodatos padre">
                        Metre
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={2} className="camporesultados">
                        {props.datos.bartendersB}
                      </Col>
                      <Col xs={2} className="camporesultados">
                        {props.datos.serversB}
                      </Col>
                      <Col xs={2} className="camporesultados">
                        {props.datos.bussersB}
                      </Col>
                      <Col xs={2} className="camporesultados">
                        {props.datos.sommB}
                      </Col>
                      <Col xs={2} className="camporesultados">
                        {props.datos.polisherB}
                      </Col>
                      <Col xs={2} className="camporesultados">
                        {props.datos.hostB}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={7} className="campodatos padre">
                        Barmans
                      </Col>
                      <Col xs={5} className="camporesultados">
                        {props.datos.bartendersB}
                      </Col>{" "}
                    </Row>
                    <Row>
                      {" "}
                      <Col xs={7} className="campodatos padre">
                        Camareros
                      </Col>{" "}
                      <Col xs={5} className="camporesultados">
                        {props.datos.serversB}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={7} className="campodatos padre">
                        Ayudantes de Camareros
                      </Col>{" "}
                      <Col xs={5} className="camporesultados">
                        {props.datos.bussersB}
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col xs={7} className="campodatos padre">
                        Somelier
                      </Col>{" "}
                      <Col xs={5} className="camporesultados">
                        {props.datos.sommB}
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col xs={7} className="campodatos padre">
                        Abrillantador
                      </Col>{" "}
                      <Col xs={5} className="camporesultados">
                        {props.datos.polisherB}
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col xs={7} className="campodatos padre">
                        Metre
                      </Col>
                      <Col xs={5} className="camporesultados">
                        {props.datos.hostB}
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col xs={12} className="campodatoscomment padre">
                    <div> Comments: </div>
                  </Col>{" "}
                  <Col xs={12} className="campocomments">
                    <div> {props.datos.commentsB}</div>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row>
              <Col xs={12} className="separator" style={{ margin: 0 }}>
                Lunch
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Manager/MD: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.managerL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Covers:</div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.coverL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Food Revenue: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {format(props.datos.totalnetrevenueFoodL)} €</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Drinks Revenue: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {format(props.datos.totalnetrevenueDrinksL)} €</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Net Revenue: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {format(props.datos.totalnetrevenueL)} €</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Propinas: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {format(props.datos.propinasL)} €</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Ticket Medio: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div>
                  {" "}
                  {format(
                    ticketmedio(
                      props.datos.coverL,
                      props.datos.totalnetrevenueL
                    )
                  )}{" "}
                  €
                </div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Habituales: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.habitualesL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> VIP's: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.vipL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Invitación: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.regL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Walk-ins: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.walkinsL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> No show: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.noshowL}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={12} className="campodatoscomment padre">
                <div> Incidencias:</div>
              </Col>{" "}
              <Col xs={12} className="campocomments">
                <div>{props.datos.incidentesL}</div>
              </Col>
            </Row>
            {!isMobile ? (
              <>
                {" "}
                <Row>
                  <Col xs={2} className="campodatos padre">
                    Barmans
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Camareros
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Ayudantes de Camareros
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Somelier
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Abrillantador
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Metre
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} className="camporesultados">
                    {props.datos.bartendersL}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.serversL}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.bussersL}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.sommL}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.polisherL}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.hostL}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col xs={7} className="campodatos padre">
                    Barmans
                  </Col>
                  <Col xs={5} className="camporesultados">
                    {props.datos.bartendersL}
                  </Col>{" "}
                </Row>
                <Row>
                  {" "}
                  <Col xs={7} className="campodatos padre">
                    Camareros
                  </Col>{" "}
                  <Col xs={5} className="camporesultados">
                    {props.datos.serversL}
                  </Col>
                </Row>
                <Row>
                  <Col xs={7} className="campodatos padre">
                    Ayudantes de Camareros
                  </Col>{" "}
                  <Col xs={5} className="camporesultados">
                    {props.datos.bussersL}
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col xs={7} className="campodatos padre">
                    Somelier
                  </Col>{" "}
                  <Col xs={5} className="camporesultados">
                    {props.datos.sommL}
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col xs={7} className="campodatos padre">
                    Abrillantador
                  </Col>{" "}
                  <Col xs={5} className="camporesultados">
                    {props.datos.polisherL}
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col xs={7} className="campodatos padre">
                    Metre
                  </Col>
                  <Col xs={5} className="camporesultados">
                    {props.datos.hostL}
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col xs={12} className="campodatoscomment padre">
                <div> Comments: </div>
              </Col>{" "}
              <Col xs={12} className="campocomments">
                <div> {props.datos.commentsL}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="separator" style={{ margin: 0 }}>
                Dinner
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Manager/MD: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.managerD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Covers:</div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.coverD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Food Revenue: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {format(props.datos.totalnetrevenueFoodD)} €</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Drinks Revenue: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados ">
                <div> {format(props.datos.totalnetrevenueDrinksD)} €</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Net Revenue: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div>{format(props.datos.totalnetrevenueD)} €</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Propinas: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {format(props.datos.propinasD)} €</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Ticket Medio: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div>
                  {" "}
                  {format(
                    ticketmedio(
                      props.datos.coverD,
                      props.datos.totalnetrevenueD
                    )
                  )}{" "}
                  €
                </div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Habituales: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.habitualesD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> VIP's: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.vipD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Invitación: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {props.datos.regD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Walk-ins: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {props.datos.walkinsD}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div>No show:</div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div>{props.datos.noshowD}</div>
              </Col>{" "}
            </Row>

            <Row>
              <Col xs={12} className="campodatoscomment padre">
                <div> Incidencias:</div>
              </Col>{" "}
              <Col xs={12} className="campocomments">
                <div>{props.datos.incidentesD}</div>
              </Col>
            </Row>
            {!isMobile ? (
              <>
                {" "}
                <Row>
                  <Col xs={2} className="campodatos padre">
                    Barmans
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Camareros
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Ayudantes de Camareros
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Somelier
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Abrillantador
                  </Col>
                  <Col xs={2} className="campodatos padre">
                    Metre
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} className="camporesultados">
                    {props.datos.bartendersD}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.serversD}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.bussersD}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.sommD}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.polisherD}
                  </Col>
                  <Col xs={2} className="camporesultados">
                    {props.datos.hostD}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col xs={7} className="campodatos padre">
                    Barmans
                  </Col>
                  <Col xs={5} className="camporesultados">
                    {props.datos.bartendersD}
                  </Col>{" "}
                </Row>
                <Row>
                  {" "}
                  <Col xs={7} className="campodatos padre">
                    Camareros
                  </Col>{" "}
                  <Col xs={5} className="camporesultados">
                    {props.datos.serversD}
                  </Col>
                </Row>
                <Row>
                  <Col xs={7} className="campodatos padre">
                    Ayudantes de Camareros
                  </Col>{" "}
                  <Col xs={5} className="camporesultados">
                    {props.datos.bussersD}
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col xs={7} className="campodatos padre">
                    Somelier
                  </Col>{" "}
                  <Col xs={5} className="camporesultados">
                    {props.datos.sommD}
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col xs={7} className="campodatos padre">
                    Abrillantador
                  </Col>{" "}
                  <Col xs={5} className="camporesultados">
                    {props.datos.polisherD}
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col xs={7} className="campodatos padre">
                    Metre
                  </Col>
                  <Col xs={5} className="camporesultados">
                    {props.datos.hostD}
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Col xs={12} className="campodatoscomment padre">
                <div> Comments:</div>
              </Col>{" "}
              <Col xs={12} className="campocomments">
                <div>{props.datos.commentsD}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="separator" style={{ margin: 0 }}>
                ende
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Covers: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                {" "}
                <div> {totalcover()}</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Net Revenue:</div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {format(totalrevenue())} €</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div>AV: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados">
                <div> {format(ticketmedio(totalcover(), totalrevenue()))}€</div>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={5} className="campodatos padre">
                <div> Total Propinas: </div>
              </Col>{" "}
              <Col xs={7} className="camporesultados stried">
                <div> {format(totalPropinas())}€</div>
              </Col>
            </Row>
          </Container>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Cajareports;
