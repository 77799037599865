import { React, useEffect, useState } from "react";

import Table from "react-bootstrap/Table";
import { compruebaString, geticonTiempo } from "../Utils/Utils";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { RevenueChart } from "./ChartRevenue";
import { Container } from "react-bootstrap";

export const TablaAnalisis = ({ rest, value }) => {
  const [dataAnalisis, setDataAnalisis] = useState([]);
  const [tableData, setTableData] = useState([]);

  const analisis = async (restaurante, fechaInicio) => {
    var dateString = fechaInicio;
    var dateini = new Date(fechaInicio);

    var datefin = new Date(fechaInicio);
    dateini.setDate(dateini.getDate() - 15);

    var fechaInicioTimestamp = new Date(dateini);

    var fechaFinTimestamp = new Date(datefin);
    var datos = [];
    if (!isNaN(fechaInicioTimestamp.getTime())) {
      const q = query(
        collection(db, restaurante),
        where("fecha", ">=", fechaInicioTimestamp),
        where("fecha", "<=", fechaFinTimestamp)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        datos.push(doc.data());
      });

      setDataAnalisis(datos);
    } else {
      console.error("Fecha inválida:", dateString);
    }
  };

  // Obtén una referencia al documento que deseas actualizar

  // const actualizarfecha = async (restaurante) => {
  //   const q = query(collection(db, restaurante));

  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((docu) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     console.log(docu.id, " => ", docu.data());

  //     // var docData = docu.data();
  //     var dateString = docu.data().creado.toString();

  //     // Separa la cadena de texto en día, mes y año
  //     var parts = dateString.split("/");
  //     var day = parseInt(parts[0], 10);
  //     var month = parseInt(parts[1], 10) - 1; // Resta 1 al mes, ya que en JavaScript los meses se indexan desde 0 (enero es 0)
  //     var year = parseInt(parts[2], 10);

  //     // Crea un objeto de tipo Date utilizando los valores obtenidos
  //     var date = new Date(year, month, day);
  //     if (!isNaN(date.getTime())) {
  //       var nfecha = Timestamp.fromDate(date);

  //       updateDoc(doc(db, restaurante, docu.id), {
  //         fecha: nfecha,
  //       });
  //     } else {
  //       console.error("Fecha inválida:", dateString);
  //     }
  //   });
  // };
  const calculateTableData = () => {
    const combinedTableData = {};

    dataAnalisis.forEach((doc) => {
      const {
        weather,
        totalnetrevenueL,
        totalnetrevenueD,
        totalnetrevenueB,
        coverL,
        coverD,
        coverB,
      } = doc;

      if (!combinedTableData[weather]) {
        combinedTableData[weather] = {
          totalnetrevenueL: compruebaString(totalnetrevenueL),
          totalnetrevenueD: compruebaString(totalnetrevenueD),
          totalnetrevenueB: compruebaString(totalnetrevenueB),
          total:
            compruebaString(totalnetrevenueL) +
            compruebaString(totalnetrevenueD) +
            compruebaString(totalnetrevenueB),
          coverL: compruebaString(coverL),
          coverD: compruebaString(coverD),
          coverB: compruebaString(coverB),
          totalCover:
            compruebaString(coverL) +
            compruebaString(coverD) +
            compruebaString(coverB),
          count: 1,
        };
      } else {
        combinedTableData[weather].totalnetrevenueL +=
          compruebaString(totalnetrevenueL);
        combinedTableData[weather].totalnetrevenueD +=
          compruebaString(totalnetrevenueD);
        combinedTableData[weather].totalnetrevenueB +=
          compruebaString(totalnetrevenueB);
        combinedTableData[weather].total +=
          compruebaString(totalnetrevenueL) +
          compruebaString(totalnetrevenueD) +
          compruebaString(totalnetrevenueB);
        combinedTableData[weather].coverL += compruebaString(coverL);
        combinedTableData[weather].coverD += compruebaString(coverD);
        combinedTableData[weather].coverB += compruebaString(coverB);
        combinedTableData[weather].totalCover +=
          compruebaString(coverL) +
          +compruebaString(coverD) +
          compruebaString(coverB);
        combinedTableData[weather].count += 1;
      }
    });

    Object.keys(combinedTableData).forEach((weather) => {
      combinedTableData[weather].averageL =
        combinedTableData[weather].totalnetrevenueL /
        combinedTableData[weather].count;
      combinedTableData[weather].averageD =
        combinedTableData[weather].totalnetrevenueD /
        combinedTableData[weather].count;
      combinedTableData[weather].averageB =
        combinedTableData[weather].totalnetrevenueB /
        combinedTableData[weather].count;
      combinedTableData[weather].averagetotal =
        combinedTableData[weather].total / combinedTableData[weather].count;

      combinedTableData[weather].averagecoverB =
        combinedTableData[weather].coverB / combinedTableData[weather].count;
      combinedTableData[weather].averagecoverD =
        combinedTableData[weather].coverD / combinedTableData[weather].count;
      combinedTableData[weather].averagecoverL =
        combinedTableData[weather].coverL / combinedTableData[weather].count;
      combinedTableData[weather].averagecover =
        combinedTableData[weather].totalCover /
        combinedTableData[weather].count;
    });

    return combinedTableData;
  };

  useEffect(() => {
    setTableData(calculateTableData());
  }, [dataAnalisis]);

  useEffect(() => {
    analisis(rest, value.toISOString());
  }, [rest, value]);

  return (
    <>
      <Container
        style={{
          textAlign: "center",
          marginTop: "15px",
          marginBottom: "5px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Data for the last 14 days from the selected date
      </Container>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>Weather</th>
            <th style={{ textAlign: "center" }}>Days</th>
            <th style={{ textAlign: "center" }}>ATRB</th>
            <th style={{ textAlign: "center" }}>ATRL</th>
            <th style={{ textAlign: "center" }}>ATRD</th>
            <th style={{ textAlign: "center" }}>ATR</th>
            <th style={{ textAlign: "center" }}>ATCB</th>
            <th style={{ textAlign: "center" }}>ATCL</th>
            <th style={{ textAlign: "center" }}>ATCD</th>
            <th style={{ textAlign: "center" }}>ATR</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {Object.keys(tableData).map((weather) => (
            <tr key={weather}>
              <td>{geticonTiempo(weather)}</td>
              <td>{tableData[weather].count}</td>
              <td>{tableData[weather].averageB.toFixed(2)}</td>
              <td>{tableData[weather].averageL.toFixed(2)}</td>
              <td>{tableData[weather].averageD.toFixed(2)}</td>
              <td>{tableData[weather].averagetotal.toFixed(2)}</td>
              <td>{tableData[weather].averagecoverB.toFixed(2)}</td>
              <td>{tableData[weather].averagecoverL.toFixed(2)}</td>
              <td>{tableData[weather].averagecoverD.toFixed(2)}</td>
              <td>{tableData[weather].averagecover.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <RevenueChart data={dataAnalisis}></RevenueChart>
    </>
  );
};
