import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import { useAuth } from "../hooks/useAuth";

function Login() {
  // const handleSubmit = useCallback(async (e) => {
  //   e.preventDefault();

  //   const { email, password } = e.target.elements;
  //   const auth = getAuth();
  //   // Add a second document with a generated ID.

  // }, []);
  const { login } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    login({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  return (
    <>
      <Container className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Card>
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <Card.Body className="text-center">
                <Card.Title>Login</Card.Title>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3 " controlId="formBasicEmail">
                    <Form.Control
                      className="mt-2"
                      name="email"
                      placeholder="email"
                      type="email"
                    />
                    <Form.Control
                      name="password"
                      className="mt-3"
                      placeholder="password"
                      type="password"
                    />
                    <Button
                      variant="secondary"
                      type="submit"
                      className="mt-3 w-100"
                    >
                      Login
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
