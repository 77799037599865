import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBar } from "./Navbar/Navbar";

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <AppBar />

      {/* {user === "admin" ? <> Hola</> : <> adios</>} */}
      {outlet}
    </div>
  );
};
