import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { compruebaString } from "../Utils/Utils";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useParams } from "react-router-dom";
import { GraficaInforme } from "../Component/GraficaInforme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Grafica = ({ data }) => {
  const [dataAnalisis, setDataAnalisis] = useState([]);
  const { restaurante } = useParams();

  const fechaDeAyer = () => {
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let ayer = new Date(hoy.getTime() - DIA_EN_MILISEGUNDOS);
    return ayer;
  };
  const analisis = async (restaurante, fechaInicio) => {
    var dateString = fechaInicio;
    var dateini = new Date(fechaInicio);

    var datefin = new Date(fechaInicio);
    dateini.setDate(dateini.getDate() - 15);

    var fechaInicioTimestamp = new Date(dateini);

    var fechaFinTimestamp = new Date(datefin);
    var datos = [];
    if (!isNaN(fechaInicioTimestamp.getTime())) {
      const q = query(
        collection(db, restaurante),
        where("fecha", ">=", fechaInicioTimestamp),
        where("fecha", "<=", fechaFinTimestamp)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        datos.push(doc.data());
      });

      setDataAnalisis(datos);
    } else {
      console.error("Fecha inválida:", dateString);
    }
  };

  useEffect(() => {
    analisis(restaurante, fechaDeAyer());
  }, [dataAnalisis]);
  console.log(restaurante);
  return (
    <>
      <GraficaInforme data={dataAnalisis} />
      {/* <h1>Restaurante: {restaurante}</h1> */}
    </>
  );
};
