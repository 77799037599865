import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import LogRocket from "logrocket";
const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  const [user, setUser] = useLocalStorage("user", userData);
  const navigate = useNavigate();

  const login = async (data) => {
    const auth = getAuth();

    try {
      var user = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      console.log(user);

      setUser(data.email);
      // window.location.replace("/");

      LogRocket.init("gdg/dailyreport-gdg");
      // This is an example script - don't forget to change it!
      LogRocket.identify(localStorage.getItem("uid"), {
        name: data.email,
        email: data.email,
      });
      navigate("/reports/report", { replace: true });
    } catch (e) {
      alert(e.message);
    }
  };

  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
