import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Button, Row } from "react-bootstrap";
import { db } from "../firebase";
import { getAuth } from "@firebase/auth";
import * as yup from "yup";
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { Formik } from "formik";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import { date } from "yup/lib/locale";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import "react-toastify/dist/ReactToastify.min.css";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  file: yup.mixed().required(),
  terms: yup.bool().required().oneOf([true], "terms must be accepted"),
});

function SelectRestaurantes(props) {
  const { t } = useTranslation();
  if (props.admin === true) {
    // console.log("admin");
    return (
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>{t("cocina.restaurante")}</Form.Label>
        <Form.Select
          aria-label="Restaurant"
          value={props.selectRestaurant}
          onChange={props.handleOnChangeselect}
        >
          <option disabled selected value>Elige una opción</option>
          <optgroup label="Lobito">
            <option value="CocinaLobitoMarbella">Lobito Marbella</option>
            <option value="CocinaLobitoMadrid">Lobito Madrid</option>
            <option value="CocinaLobitoDoha">Lobito Doha</option>
          </optgroup>
          <optgroup label="BIBO">
            <option value="CocinaBIBOMarbella">BIBO Marbella</option>
            <option value="CocinaBIBODOHA">BIBO DOHA</option>
            <option value="CocinaBIBOMadrid">BIBO Madrid</option>
            <option value="CocinaBIBOTarifa">BIBO Tarifa</option>
            <option value="CocinaBIBOIbiza">BIBO Ibiza</option>
            <option value="CocinaBIBOLondon">BIBO London</option>
          </optgroup>
          <optgroup label="Leña">
            <option value="CocinaLenaMarbella">Leña Marbella</option>
            <option value="CocinaLenaMadrid">Leña Madrid</option>
          </optgroup>
          <optgroup label="Smoked Room">
            <option value="CocinaSmokedRoom">Smoked Room</option>
          </optgroup>
          <optgroup label="Babette">
            <option value="CocinaBabetteMarbella">Babette Marbella</option>
          </optgroup>
          <optgroup label="CasaDani">
            <option value="CocinaCasaDaniNY">Casa Dani NY</option>
          </optgroup>
          <optgroup label="PolloVerde">
            <option value="CocinaPolloVerdeNY">Pollo Verde NY</option>
          </optgroup>
          <optgroup label="Alelí">
            <option value="CocinaAleliMarbella">Alelí Marbella</option>
          </optgroup>
          <optgroup label="Kemuri">
            <option value="CocinaKemuriMarbella">Kemuri Marbella</option>
          </optgroup>{" "}
          <optgroup label="Kurai">
            <option value="CocinaKuraiMarbella">Kurai Marbella</option>
          </optgroup>
          <optgroup label="Tragabuches">
            <option value="CocinaTragabuchesMarbella">
              Tragabuches Marbella
            </option>
          </optgroup>
          <optgroup label="Coleccionista">
            <option value="CocinaColeccionistaMadrid">
              El Coleccionista Madrid
            </option>
          </optgroup>
          <optgroup label="Produccion">
            <option value="Produccion">Producción</option>
          </optgroup>
        </Form.Select>
      </Form.Group>
    );
  } else if (
    (props.admin === false &&
      props.selectRestaurant === "CocinaKemuriMarbella") ||
    props.selectRestaurant === "CocinaKuraiMarbella"
  ) {
    // console.log("admin");
    return (
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>{t("home.restaurante")}</Form.Label>
        <Form.Select
          aria-label="Restaurant"
          value={props.selectRestaurant}
          onChange={props.handleOnChangeselect}
        >
          <option disabled selected value>Elige una opción</option>

          <optgroup label="Kemuri">
            <option value="CocinaKemuriMarbella">Kemuri Marbella</option>
          </optgroup>
          <optgroup label="Kurai">
            <option value="CocinaKuraiMarbella">Kurai Marbella</option>
          </optgroup>
        </Form.Select>
      </Form.Group>
    );
  } else {
    // console.log("no admin");
    return (
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>{t("cocina.restaurante")}</Form.Label>
        <Form.Select
          aria-label="Restaurant"
          value={props.selectRestaurant}
          onChange={props.handleOnChangeselect}
          disabled
        >
          <option disabled selected value>Elige una opción</option>
          <optgroup label="Lobito">
            <option value="CocinaLobitoMarbella">Lobito Marbella</option>
            <option value="CocinaLobitoMadrid">Lobito Madrid</option>
            <option value="CocinaLobitoDoha">Lobito Doha</option>
          </optgroup>
          <optgroup label="BIBO">
            <option value="CocinaBIBOMarbella">BIBO Marbella</option>
            <option value="CocinaBIBODOHA">BIBO DOHA</option>
            <option value="CocinaBIBOMadrid">BIBO Madrid</option>
            <option value="CocinaBIBOTarifa">BIBO Tarifa</option>
            <option value="CocinaBIBOIbiza">BIBO Ibiza</option>
            <option value="CocinaBIBOLondon">BIBO London</option>
          </optgroup>
          <optgroup label="Leña">
            <option value="CocinaLenaMarbella">Leña Marbella</option>
            <option value="CocinaLenaMadrid">Leña Madrid</option>
          </optgroup>
          <optgroup label="Smoked Room">
            <option value="CocinaSmokedRoom">Smoked Room</option>
          </optgroup>
          <optgroup label="Babette">
            <option value="CocinaBabetteMarbella">Babette Marbella</option>
          </optgroup>
          <optgroup label="CasaDani">
            <option value="CocinaCasaDaniNY">Casa Dani NY</option>
          </optgroup>
          <optgroup label="PolloVerde">
            <option value="CocinaPolloVerdeNY">Pollo Verde NY</option>
          </optgroup>
          <optgroup label="Alelí">
            <option value="CocinaAleliMarbella">Alelí Marbella</option>
          </optgroup>
          <optgroup label="Kemuri">
            <option value="CocinaKemuriMarbella">Kemuri Marbella</option>
          </optgroup>{" "}
          <optgroup label="Kurai">
            <option value="CocinaKuraiMarbella">Kurai Marbella</option>
          </optgroup>
          <optgroup label="Tragabuches">
            <option value="CocinaTragabuchesMarbella">
              Tragabuches Marbella
            </option>
          </optgroup>
          <optgroup label="Coleccionista">
            <option value="CocinaColeccionistaMadrid">
              El Coleccionista Madrid
            </option>
          </optgroup>
          <optgroup label="Produccion">
            <option value="Produccion">Producción</option>
          </optgroup>
        </Form.Select>
      </Form.Group>
    );
  }
}

// const Traduccion = withTranslation()(SelectRestaurantes);
function Produccion(props) {
  const [selectRestaurant, setSelectRestaurant] = React.useState();
  // eslint-disable-next-line no-unused-vars
  const [datosquery, setDatosquery] = React.useState([]);
  const [admin, setAdmin] = React.useState(false);

  const notify = () =>
    toast.success(t("cocina.toastOk"), {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      onClose: () => window.location.replace("/"),
    });
  const notifyerror = (error) =>
    toast.danger("Error" + error, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  const subirDatosIncidencia = async (e) => {
    console.log(e);

    try {
      await addDoc(collection(db, selectRestaurant), {
        restaurant: selectRestaurant,

        produccionesL: e.produccionesL,
        incMaquinariaL: e.incMaquinariaL,
        incProveedoresL: e.incProveedoresL,
        incTrabajadoresL: e.incTrabajadoresL,
        commentsL: e.commentsL,
        fecha: Timestamp.now(),
        jefeCocinaL: e.jefeCocinaL,
        secCocinaL: e.secCocinaL,
        supervisorL: e.supervisorL,
        cocineroL: e.cocineroL,
        ayucocinaL: e.ayucocinaL,
        officeL: e.officeL,
        jefePartidaL: e.jefePartidaL,
        incidenciaId: uuidv4(),
        creado: datevalue.toLocaleDateString(),
        hora: datevalue.toTimeString(),
      }).then(notify());
    } catch (e) {
      console.error("Error adding document: ", e);
      notifyerror(notifyerror(e));
    }
  };

  const handleOnChangeselect = (e) => {
    e.preventDefault();

    setSelectRestaurant(e.target.value);
  };
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    rolequery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function rolequery() {
    const q = query(
      collection(db, "user"),
      where("uid", "==", user !== null ? user.uid : "")
    );
    const cities = [];

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      var encontrado = false;
      cities.forEach((element) => {
        if (element.uid === doc.data().uid) {
          encontrado = true;
        }
      });
      if (!encontrado) {
        cities.push(doc.data());
      }
    });
    // console.log(cities);

    setDatosquery(cities);

    if (cities.length !== 0) {
      if (cities[0].rol === "Admin") {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
      setSelectRestaurant(cities[0].idrestaurante);
    }
  }
  const fechaDeAyer = () => {
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let ayer = new Date(hoy.getTime() - DIA_EN_MILISEGUNDOS);
    return ayer;
  };

  const [datevalue, dateonChange] = useState(fechaDeAyer());
  const { t } = useTranslation();

  return (
    <Container className="my-5">
      <ToastContainer />
      <Row>
        <Col xs={12}>
          <Card className="p-3">
            <Card.Title>{t("cocina.title")}</Card.Title>
            <hr></hr>
            <Card.Body>
              <Formik
                validationSchema={schema}
                initialValues={{
                  jefeCocinaL: "",
                  produccionesL: 0,
                  incProveedoresL: "",
                  incTrabajadoresL: "",
                  commentsL: "",
                  incMaquinariaL: "",
                  jefePartidaL: "",
                  secCocinaL: "",
                  supervisorL: "",
                  cocineroL: "",
                  ayucocinaL: "",
                  officeL: "",
                }}
              >
                {({ handleChange, values, isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col xs={12} md={6}>
                        <SelectRestaurantes
                          admin={admin}
                          text={t("cocina.restaurante")}
                          selectRestaurant={selectRestaurant}
                          handleOnChangeselect={handleOnChangeselect}
                        ></SelectRestaurantes>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.selectDay")}</Form.Label>
                          <DatePicker
                            className="fecha_select"
                            onChange={dateonChange}
                            minDate={fechaDeAyer()}
                            maxDate={new Date()}
                            value={datevalue}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="separator">
                        {t("cocina.resumen")}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.produccionesL")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="produccionesL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.produccionesL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incMaquinaria")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="incMaquinariaL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incMaquinariaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incProveedores")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="incProveedoresL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incProveedoresL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.incTrabajadores")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="incTrabajadoresL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incTrabajadoresL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>Jefe Cocina</Form.Label>
                          <Form.Control
                            type="text"
                            name="jefeCocinaL"
                            value={values.jefeCocinaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>Segundo Cocina</Form.Label>
                          <Form.Control
                            type="text"
                            name="secCocinaL"
                            value={values.secCocinaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>Supervisor Cocina</Form.Label>
                          <Form.Control
                            type="text"
                            name="supervisorL"
                            value={values.supervisorL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>Jefe Partida</Form.Label>
                          <Form.Control
                            type="text"
                            name="jefePartidaL"
                            value={values.jefePartidaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>Cocinero</Form.Label>
                          <Form.Control
                            type="text"
                            name="cocineroL"
                            value={values.cocineroL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>Ayudante Cocina</Form.Label>
                          <Form.Control
                            type="text"
                            name="ayucocinaL"
                            value={values.ayucocinaL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>Office</Form.Label>
                          <Form.Control
                            type="text"
                            name="officeL"
                            value={values.officeL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("cocina.comentarios")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="commentsL"
                            value={values.commentsL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        isSubmitting = true;
                        confirmAlert({
                          title: t("cocina.warningtitle"),
                          message: t("cocina.warningdesc"),
                          buttons: [
                            {
                              label: t("cocina.enviar"),
                              onClick: () => subirDatosIncidencia(values),
                            },
                            {
                              label: t("cocina.cancelar"),
                              onClick: () => (isSubmitting = false),
                            },
                          ],
                        });
                      }}
                      className="w-100 my-3"
                      disabled={isSubmitting}
                    >
                      {t("cocina.enviar")}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Produccion;
